import style from './CallMe.module.scss';
import {useState} from "react";
import {useMessage} from "../../hooks/message.hook";

function CallMe({active, setActive}) {

    const [phone, setPhone] = useState('');
    const [firstname, setFirstName] = useState('');
    const [isSending, setIsSending] = useState(false); // Для индикации отправки
    const [message, setMessage] = useState(''); // Для отображения сообщений
    const messages = useMessage()
    // Асинхронная функция для отправки данных на сервер
    const handleSubmit = async () => {
        if (!phone || !firstname) {
            messages('Пожалуйста, заполните все поля');
            return;
        }

        setIsSending(true); // Начало отправки данных

        const formData = {
            phone,
            firstname
        };

        try {
            const response = await fetch('/post.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json(); // Ожидаем JSON ответ от PHP

            if (result.success) {
                messages('Данные успешно отправлены!');
                setPhone(''); // Очистка полей после успешной отправки
                setFirstName('');
                setActive(false)
            } else {
                messages('Ошибка отправки данных, попробуйте позже.');
            }
        } catch (error) {
            console.error('Ошибка при отправке формы:', error);
            messages('Ошибка сети, попробуйте снова.');
        } finally {
            setIsSending(false); // Завершаем индикацию отправки
        }
    };

    return (
        <div className={style.main} style={(active) ? {display: 'block'} : {}}>
            <div className={style.container}>
                <div className={style.up}>
                    <img src="/files/SVG/lmonster.svg" alt="Логотип"/>
                    <i className="fa-regular fa-circle-xmark" onClick={() => setActive(false)}></i>
                </div>
                <div className={style.content}>
                    <input
                        type="number"
                        placeholder='Номер телефона'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder='Ваше имя'
                        value={firstname}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className={style.btn} onClick={handleSubmit}>
                        {isSending ? 'Отправка...' : 'Отправить'}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CallMe;