import style from './FooterBlock.module.scss'
import CallMe from "../form/CallMe";
import {useState} from "react";

function FooterBlock(){

    const [active, setActive] = useState(false)

    return  (
        <div className={style.main}>
            <CallMe active={active} setActive={setActive}/>
            <div className={style.container}>
                <div className={style.email}>need@logotype.monster</div>
                <div className={style.btn} onClick={()=>setActive(true)}>Заказать звонок</div>
            </div>
        </div>
    )
}

export default FooterBlock