import style from './HeaderBlock.module.scss'
import {Link} from "react-router-dom";

function HeaderBlock({ titler }){
    return(
        <div className={style.newcap}>
            <div className={style.up}>
                <Link to='/' className={style.logoimg}><img src="/files/SVG/lmonster.svg"/></Link>
                <Link to='/' className={style.menublock}>
                    <div className={style.titler}>LOGOS</div>
                    <div className={style.link}>portfolio</div>
                </Link>
                <Link to='/fonts' className={style.menublock}>
                    <div className={style.titler}>FONTS</div>
                    <div className={style.link}>free fonts</div>
                </Link>
            </div>
            <div className={style.down}>
                <div className={style.left}><label style={{color: '#454545'}}>{titler[0]}</label> <label style={{color: '#CCCCCC'}}>{titler[1]}</label></div>

            </div>
        </div>
    )
}

export default HeaderBlock