import style from './styles/Fonts.module.scss'
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import HeaderBlock from "../components/header/HeaderBlock";

function Fonts () {

    const [open, setOpen] = useState(false)

    return (
        <div className={style.bodymain}>
            <div className={style.moreblock} style={(open)?{display:'flex'}:{display:'none'}}>
                <div className={style.text}>Я не собираю твои персональные данные, но всегда читаю почту. Напиши мне</div>
                <div className={style.email}>logotypemonster@yandex.ru</div>
                <div className={style.exit}><i className="fa-regular fa-circle-xmark"  onClick={()=>setOpen(false)}/></div>
            </div>
            <div className={style.container}>
                <HeaderBlock  titler={['FREE','FONTS']}/>
                <div className={style.fonts}>
                    <div className={style.sing}>
                        <div className={style.example} style={{fontFamily: 'Barahta'}}>
                            <div className={`${style.low} ${style.middle} ${style.mobile}`} style={{fontFamily: 'Barahta'}}>
                                Logotype.monster
                            </div>
                            <div style={{fontFamily: 'Barahta'}} className={`${style.upp} ${style.middle} ${style.mobile}`} >
                                Logotype
                            </div>
                        </div>
                        <a href="/files/fonts/Barahta.otf" download className={style.btn}>
                            Download
                        </a>
                    </div>
                    <div className={style.sing}>
                        <div className={style.example} style={{fontFamily: 'BarahtaStone'}}>
                            <div className={`${style.upp} ${style.middle} ${style.mobile}`} style={{fontFamily: 'BarahtaStone'}}>
                                Logotype.monster
                            </div>
                        </div>
                        <a href="/files/fonts/BarahtaKino.otf" download className={style.btn}>
                            Download
                        </a>
                    </div>
                    <div className={style.sing}>
                        <div className={style.example} style={{fontFamily: 'BarahtaMonster'}}>
                            <div className={`${style.upp} ${style.middle} ${style.mobile}`} style={{fontFamily: 'BarahtaMonster'}}>
                                Logotype.monster
                            </div>
                        </div>
                        <a href="/files/fonts/BarahtaMonster.otf" download className={style.btn}>
                            Download
                        </a>
                    </div>
                    <div className={style.sing}>
                        <div className={style.example} style={{fontFamily: 'BarahtaKino'}}>
                            <div className={`${style.upp} ${style.middle} ${style.mobile}`} style={{fontFamily: 'BarahtaKino'}}>
                                Logotype.monster
                            </div>
                        </div>
                        <a href="/files/fonts/BarahtaKino.otf" download className={style.btn}>
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fonts